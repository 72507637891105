import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useSidebar } from '../../hooks/useSidebar';
import DropdownSublist from '../DropdownSublist';
import {
  Container as GatsbyContainer,
  LogoContainer,
  List,
  Heading,
  Item as GatsbyItem,
  SubItem,
} from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/styles';
import styled from '@emotion/styled';
import { isExternalUrl } from '@rocketseat/gatsby-theme-docs/src/util/url';
import ExternalLink from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/ExternalLink';
import InternalLink from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/InternalLink';
import Logo from '@rocketseat/gatsby-theme-docs/src/components/Logo';

// Make sidebar wide enough to hold Configuration Parameters without wrapping
const Container = styled(GatsbyContainer)`
  max-width: 290px;
  min-width: 290px;`;

// If an item is going to wrap because of the hover transition, make it wrap always
export const Item = styled(GatsbyItem)`
  a, span {
    transition: background-color 0.2s, color 0.2s, padding-left 0.2s, padding-right 0.2s;
    &:not(.active-link):not(:hover) {
      padding-right: 20px;
    }
  }`;

function ListWithSubItems({ children, text }) {
  return (
    <>
      <Heading>{text}</Heading>
      <SubItem>{children}</SubItem>
    </>
  );
}

export default function Sidebar({ isMenuOpen }) {
  const {
    site: {
      siteMetadata: { footer },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          footer
          basePath
        }
      }
    }
  `);

  const data = useSidebar();
  const rootElem = useRef()

  function renderLink(link, label) {
    return isExternalUrl(link) ? (
      <ExternalLink link={link} label={label} />
    ) : (
        <InternalLink link={link} label={label} />
      );
  }

  useEffect(() => {
    rootElem.current.scrollTop = sessionStorage.getItem('sidebarScrollPos') || 0
  }, [])

  return (
    <Container isMenuOpen={isMenuOpen}
      ref={rootElem}
      onScroll={(event) => { sessionStorage.setItem('sidebarScrollPos', event.target.scrollTop) }}>
      <LogoContainer style={{minHeight: "100px", minWidth: "600px"}}>
        <a href={'http://www.tickitto.com'}>
          <Logo />
        </a>
      </LogoContainer>
      <nav>
        <List>
          {data.map(({ node: { label, link, items, id } }) => {
            if (Array.isArray(items)) {
              const subitems = items.map(item => {
                if (Array.isArray(item.items) && item.items.length === 0) {
                  return (
                    <Item key={item.link}>
                      {renderLink(item.link, item.label)}
                    </Item>
                  );
                } else {
                  const itemDropdowns = item.items
                  return (
                    <DropdownSublist key={item.link} itemDropdowns={itemDropdowns} link={item.link} label={item.label} renderLink={renderLink} />
                  )
                }
              });

              return (
                <ListWithSubItems key={id} text={label}>
                  {subitems}
                </ListWithSubItems>
              );
            }

            return <Item key={id}>{renderLink(link, label)}</Item>;
          })}
        </List>
      </nav>
      <footer>
        <p>{footer}</p>
      </footer>
    </Container>
  );
}

ListWithSubItems.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  text: PropTypes.string.isRequired,
};

Sidebar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};