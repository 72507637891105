export default {
  colors: {
    primary: "#5D4A9A",
    background: "#FFFFFF",
    text: "#211349",
    sidebar: {
      background: "#F8F8F8",
      link: "#000000",
      heading: "#000000",
      linkActive: "#6049A0",
      itemActive: "#F8FAFF",
      footer: "#4F5157",
    },
  },
};
