import React, { useState, useContext } from 'react'
import { DownArrowSVG } from '../../@rocketseat/gatsby-theme-docs/components/DropdownSublist'
import styled from '@emotion/styled';

const MainContainer = styled.div`
  width:100%;

  h3{
    position: relative;
    margin: 10px;
  }

`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const ArrowButton = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
width: 100%;
overflow: hidden;
padding: 0 0 0 10px;
height: ${props => props.isOpen ? 'unset' : '0px'};
transition: height .4s ease-out;
`

export default function CollapsibleSection({ children, title }) {
  const [isOpen, toggleOpen] = useState(false)

  return (
    <MainContainer>
      <HeaderContainer>
        <ArrowButton onClick={() => toggleOpen(!isOpen)} >
          <DownArrowSVG isPointingDown={!isOpen} />
        </ArrowButton>
        <h3>
          <strong>{title}</strong>
        </h3>
      </HeaderContainer>
      <Content isOpen={isOpen}>
        {children}
      </Content>
    </MainContainer>
  )
}