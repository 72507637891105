import React, { useState } from 'react'
import styled from '@emotion/styled';
import { List } from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/styles';
import { Item } from '../Sidebar';

const SublistHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: 600;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  li {
    animation: fadein 2s;
    font-weight: 600;
  }

  span {
    font-weight: 600;
  }
`;

const ArrowButton = styled.button`
  border: 0;
  padding-left: 10px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

const SublistContainer = styled.div`
  width: 100%;
  padding-left: 20px;
`;

export default function DropdownSublist({
  label,
  link,
  itemDropdowns,
  renderLink,
}) {
  let itemIsActive = false;
  if (typeof window !== "undefined") {
    itemIsActive = itemDropdowns.some(
      (elem) => elem.link === window.location.pathname
    );
  }
  const storedDropdownVal =
    typeof sessionStorage !== "undefined"
      ? sessionStorage.getItem(label + "dropdown") === "true"
      : false;
  const [isOpen, toggleOpen] = useState(itemIsActive || storedDropdownVal);

  const handleToggle = (newVal) => {
    sessionStorage.setItem(label + "dropdown", newVal);
    toggleOpen(newVal);
  };
  return (
    <>
      <Item>
        <SublistHeader className onClick={() => handleToggle(!isOpen)}>
          <ArrowButton>
            <DownArrowSVG isPointingDown={!isOpen} />
          </ArrowButton>
          <span>{label}</span>
          {/* {renderLink(link, label)} */}
        </SublistHeader>
      </Item>

      {isOpen && (
        <SublistContainer>
          <List>
            {itemDropdowns.map((elem) => {
              return (
                <Item key={elem.link}>{renderLink(elem.link, elem.label)}</Item>
              );
            })}
          </List>
        </SublistContainer>
      )}
    </>
  );
}

export function DownArrowSVG({ isPointingDown }) {
  const ArrowSVG = styled.svg`
    height: 14px;
    width: 14px;
    transform: rotate(${isPointingDown ? "0" : "180deg"});
    transition: all 1s linear;
  `;

  return (
    <ArrowSVG
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 284.929 284.929"
      fill="#6049A0"
      space="preserve"
    >
      <g>
        <path
          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
          transform="translate(0.05 -0.025)"
        />
      </g>
    </ArrowSVG>
  );
}
